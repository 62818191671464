var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.created ? _c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'previous');
      }
    }
  }, [_vm._v(" Save & close ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'next');
      }
    }
  }, [_vm._v(" Save & next ")])], 1), _c('v-card-text', {
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.template.setSlug(_vm.template.slug || _vm.template.name);
      }
    },
    model: {
      value: _vm.template.name,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "name", $$v);
      },
      expression: "template.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.template.setSlug(_vm.template.slug);
      }
    },
    model: {
      value: _vm.template.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "slug", $$v);
      },
      expression: "template.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "label": "Partial?"
    },
    model: {
      value: _vm.template.isPartial,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "isPartial", $$v);
      },
      expression: "template.isPartial"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "label": "Is Enabled?"
    },
    model: {
      value: _vm.template.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "isActive", $$v);
      },
      expression: "template.isActive"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "name": "type",
      "label": "Partials",
      "items": _vm.templates,
      "item-text": "name",
      "item-value": "_id"
    },
    model: {
      value: _vm.partial,
      callback: function callback($$v) {
        _vm.partial = $$v;
      },
      expression: "partial"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addPartials();
      }
    }
  }, [_vm._v("Add Partials")])], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.importJson();
      }
    }
  }, [_vm._v("Import JSON")])], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_c('EmailEditor', {
    ref: "emailEditor",
    staticStyle: {
      "height": "600px"
    },
    attrs: {
      "options": _vm.options
    },
    on: {
      "load": _vm.editorLoaded,
      "ready": _vm.editorReady
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('vx-dialog', {
    attrs: {
      "actions": "",
      "title": "Import JSON",
      "width": "600",
      "min-height": "600"
    },
    on: {
      "resolve": function resolve($event) {
        return _vm.onResolve();
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea"
    },
    model: {
      value: _vm.json,
      callback: function callback($$v) {
        _vm.json = $$v;
      },
      expression: "json"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }