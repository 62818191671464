<template>
  <v-form ref="form" lazy-validation>
    <v-card v-if="created">
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'previous')">
          Save & close
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'next')">
          Save & next
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="template.name"
              type="text"
              name="name"
              label="Name"
              required
              @change="template.setSlug(template.slug || template.name)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="template.slug"
              type="text"
              name="slug"
              label="Slug"
              required
              :disabled="$route.params.id && !dev"
              @change="template.setSlug(template.slug)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="template.isPartial" type="checkbox" label="Partial?" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="template.isActive" type="checkbox" label="Is Enabled?" />
          </v-col>

          <v-col cols="12" sm="3" class="py-0">
            <vx-input
              v-model="partial"
              type="autocomplete"
              name="type"
              label="Partials"
              :items="templates"
              item-text="name"
              item-value="_id"
            />
          </v-col>

          <v-col cols="12" sm="3" class="py-0">
            <vx-btn block outlined color="primary" @click="addPartials()">Add Partials</vx-btn>
          </v-col>

          <v-col cols="12" sm="3" class="py-0">
            <vx-btn block outlined @click="importJson()">Import JSON</vx-btn>
          </v-col>

          <v-col cols="12" sm="12" class="py-0">
            <EmailEditor
              ref="emailEditor"
              style="height: 600px"
              :options="options"
              @load="editorLoaded"
              @ready="editorReady"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <vx-dialog v-model="dialog" actions title="Import JSON" width="600" min-height="600" @resolve="onResolve()">
      <vx-input v-model="json" type="textarea" />
    </vx-dialog>
  </v-form>
</template>

<script>
import { Template } from '@tutti/models';
import { TemplateService } from '@tutti/services';

import { EmailEditor } from 'vue-email-editor';
import mergeTags from './mergeTags.json';

export default {
  name: 'LandingPageEdit',

  components: { EmailEditor },

  data() {
    return {
      tab: 0,
      loading: false,
      created: false,
      template: new Template(),
      options: {
        features: {
          preview: true,
        },
      },
      partial: '',
      json: '',
      dialog: false,
      templates: [],
    };
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getTemplate();
    this.created = true;
    this.getTemplates();
  },

  methods: {
    importJson() {
      this.json = JSON.stringify(this.template.design);
      this.dialog = true;
    },
    onResolve() {
      try {
        const design = JSON.parse(this.json);
        this.$refs.emailEditor.editor.loadDesign(design);
      } catch (error) {
        this.$error('Invalid JSON');
      }
    },
    addPartials() {
      if (this.partial) {
        this.$refs.emailEditor.editor.exportHtml(async data => {
          const response = await TemplateService.getById(this.partial);

          if (response) {
            data.design.body.rows.push(response.data.design.body.rows[0]);
            this.$refs.emailEditor.editor.loadDesign(data.design);
          }
        });
      }
    },
    // called when the editor is created
    editorLoaded() {
      // Pass the template JSON here
      if (this.template.design) {
        this.$refs.emailEditor.editor.loadDesign(this.template.design);
      }

      this.$refs.emailEditor.editor.setMergeTags(mergeTags);
    },
    // called when the editor has finished loading
    editorReady() {
      // console.log('editorReady');
    },
    exportHtml() {
      return new Promise(resolve => {
        this.$refs.emailEditor.editor.exportHtml(data => {
          resolve(data);
        });
      });
    },

    async getTemplate() {
      if (this.$route.params.id) {
        const response = await TemplateService.getById(this.$route.params.id);

        if (response) {
          this.template = new Template(response.data);
          this.$setTitle(this.template.name);
        }
      } else {
        const response = await TemplateService.getById('default');

        if (response) {
          this.template.design = response.data.design;
        }
      }
    },

    async getTemplates() {
      const response = await TemplateService.getAll({ filter: { isPartial: true } });

      if (response) {
        this.templates = response.data.data;
      }
    },

    async save(action) {
      this.loading = true;
      const data = await this.exportHtml();
      this.template.html = data.html;
      this.template.design = data.design;
      const response = await TemplateService.save(this.template.get());
      if (response) {
        if (action === 'next') {
          this.template = new Template();
          this.$reset();
          this.$setTitle('');
        } else if (action === 'previous') {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('template', response.data._id));
        }
      }
      this.loading = false;
    },
  },
};
</script>
